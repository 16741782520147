import { useEffect, useState } from "react";
import { ProductCarousel } from "./ProductCarousel";
import type { AlgoliaItem } from "@lib/analytics/AnalyticsClient";
import { AnalyticsClient } from "@lib/analytics/AnalyticsClient";
import hexRgb from "hex-rgb";
import { NextLink } from "@ui/components/navigation/link/NextLink";
import type { IPagePath } from "@contentful-api/types/contentful";
import type { Document } from "@contentful/rich-text-types";
import fetchService from "@lib/helpers/fetchService";

interface ISearchProductsHitsToCarouselProps {
    products?: string[];
    headline?: string;
    assistiveMessage?: string;
    sortingOrder?: string[];
    carouselType?: string;
    authorizationInvitation?: Document;
    extraLink?: IPagePath;
    extraLinkLabel?: string;
    id?: string;
}

export const SearchProductsHitsToCarousel = (props: ISearchProductsHitsToCarouselProps) => {
    const {
        products,
        headline,
        assistiveMessage,
        sortingOrder,
        carouselType,
        authorizationInvitation,
        extraLink = null,
        extraLinkLabel = null,
        id,
    } = props;

    const [loadHits, setLoadHits] = useState(true);
    const [hits, setHits] = useState<any[]>([]);
    const [filterString, setFilterString] = useState(products.join(","));

    // Using product filter string as an state to verify if we need to re-fetch the products.
    if (filterString !== products.join(",")) {
        setLoadHits(true);
        setFilterString(products.join(","));
    }

    const fetchProducts = async () => {
        setLoadHits(false);
        try {
            const params = new URLSearchParams({ products: filterString });
            const response = await fetchService.get<any[]>(`/api/algolia-browse?` + params);
            setHits(response);
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    };

    // Only load if there is no other loading started.
    if (loadHits) {
        fetchProducts();
    }

    const fixedHits = hits.map((hit, index) => {
        return {
            ...hit,
            id: hit.id ?? hit.objectID,
            productColors: Array.isArray(hit.productColors)
                ? hit.productColors?.map((it) => ({
                      priceWithDiscount: it.priceRaw,
                      priceWithoutDiscount: it.listPriceRaw,
                      image: it.image,
                      objectID: it.objectID,
                      colorCode: it.colorCode,
                      imageBackgroundColor: it.imageBackgroundColor,
                      discountPercentage: it.discountPercentage,
                      hasDiscount: it.hasDiscount,
                      hasStock: it.hasStock,
                  }))
                : hit.productColors,
            queryID: hit.__queryID,
            index,
            productName: hit.name,
            priceWithoutDiscount: hit.listPriceRaw,
            priceWithDiscount: hit.priceRaw,
            backgroundColor: hexRgb("FFF"),
            imageBackgroundColor: hexRgb((hit.backgroundColor ?? "FFF") as string, {
                format: "css",
                alpha: 1,
            }),
        };
    });

    if (sortingOrder && sortingOrder?.length) {
        fixedHits?.sort?.((a, b) => {
            return (
                sortingOrder?.findIndex((it) => it === a.objectID) -
                sortingOrder?.findIndex((it) => it === b.objectID)
            );
        });
    }

    useEffect(() => {
        const analyticsTimeout = setTimeout(
            () =>
                AnalyticsClient.loadItemsList(fixedHits as unknown as AlgoliaItem[], {
                    listName: carouselType,
                }),
            3000
        );
        return () => {
            clearTimeout(analyticsTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fixedHits]);

    const ExtraLinkComponent = () => {
        if (extraLink) {
            const completeSlug = extraLink?.fields.completeSlug;
            const linkLabel = extraLink?.fields.label;
            return <NextLink href={completeSlug}>{extraLinkLabel ?? linkLabel}</NextLink>;
        }
        return null;
    };

    //TODO: INTL assistiveMessage
    return (
        <ProductCarousel
            slideData={fixedHits}
            headline={headline}
            assistiveMessage={assistiveMessage}
            carouselType={carouselType}
            authorizationInvitation={authorizationInvitation}
            extraLink={<ExtraLinkComponent />}
            id={id}
        />
    );
};
